
import { defineComponent } from 'vue'
import CardUser from '@/components/core/user/CardUser.vue'
import { User } from '@/model/User'

export default defineComponent({
  name: `ViewContacts`,
  components: { CardUser },
  props: {},
  emits: [],
  setup() {
    const users: User[] = [
      {
        username: 'Thibert',
        email: 't.letullier@digithia.fr',
        activated: true,
      },
      { username: 'Sébastien', email: 's.albert@digithia.fr', activated: true },
    ]

    return { users }
  },
})
